<template>
  <header class="gridx">
      <vs-navbar v-model="indexActive"
                 :color="topbarColor"
                 class="topnavbar"
                 text-color="rgba(255,255,255,0.7)"
                 active-text-color="rgba(255,255,255,1)">
          <!---
    Template logo
    -->
          <div slot="title" class="themelogo">
              <img :src="logo" v-if="logo" alt="Dashboard" />
              <span class="logo-text" v-if="title">{{ title }}</span>
          </div>

          <vs-spacer></vs-spacer>

          <vs-navbar-item index="1">
              <a href="/#/Ranking">Entrar</a>
          </vs-navbar-item>
          <!--
    <vs-navbar-item index="2">
        <a href="/#/Surveys">Surveys</a>
    </vs-navbar-item>
    <vs-navbar-item index="3">
        <a href="/#/Ranking">Ranking</a>
    </vs-navbar-item>
        -->

          <vs-dropdown vs-trigger-click class="cursor-pointer pr-2 pl-2 ml-1 mr-1">
              <a class="small-icon text-white-dark" href="#">
                  {{getCurrentLanguage.lang}}
                  <vs-icon icon="expand_more"></vs-icon>
              </a>
              <vs-dropdown-menu class="topbar-dd">
                  <vs-dropdown-item @click="changeLanguage('es')">Español</vs-dropdown-item>
                  <vs-dropdown-item @click="changeLanguage('pt')">Português</vs-dropdown-item>
                  <vs-dropdown-item @click="changeLanguage('en')">English</vs-dropdown-item>
                  <vs-dropdown-item @click="changeLanguage('fr')">French</vs-dropdown-item>
              </vs-dropdown-menu>
          </vs-dropdown>

      </vs-navbar>
  </header>
</template>

<script>

export default {
  name: "Navbar",
  props: {
    topbarColor: {
      type: String,
      default: "#233859"
    },
    title: {
        type: String,
        default: ""
    },
    logo: {
      type: String,
      default: "../../../../public/favicon.ico"
    },
    // Obj for Userdropdown
    user: Object
  },
  data: () => ({
    indexActive: 0
  }),

  methods: {
    //This is for sidebar trigger in mobile
   
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    }
  },
  computed: {
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { lang: "English" };
      else if (locale == "fr") return { lang: "French" };   
          else if (locale == "pt") return { lang: "Português" };
          else if (locale == "es") return { lang: "Español" };
      return this.locale;
    }
  },
  components: {
    
  }
};
</script>
