<template>
	<div class="main-wrapper" :class="[mainWrapperClass]">
		<!---Navigation-->
		<Navbar :topbarColor="topbarColor" :logo="require('@/assets/images/logo/logo-arena.png')"  />		
		<!---Page Container-->
		<div class="main-container-fluid">
		
		<transition name="router-anim" enter-active-class="fade-enter-active fade-enter" leave-active-class="fade-leave-active fade-enter">			
		<router-view></router-view>
		</transition>		
		</div>	
	</div>
</template>

<script>
 
import Navbar from '@/layout/full/header/HomeBar.vue';


export default {
name: "MainContainer",
components: {
Navbar
},
data:() => ({
	
}),
methods: {
	updateTopbarColor(val) {
     this.topbarColor = val;
  }
    
        },  
        
computed: {

mainWrapperClass: function() {
      return "default"	
    }

},

}  	
</script>